import React from 'react';
import { useTranslation } from 'react-i18next';

const Hero: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 text-center bg-gradient-to-b from-blue-600 to-blue-800 text-white dark:from-blue-800 dark:to-blue-900">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">Web3Insure</h1>
        <p className="text-xl md:text-2xl mb-8">{t('slogan')}</p>
        <div className="max-w-3xl mx-auto">
          <p className="text-lg mb-8">{t('introduction')}</p>
          <div className="flex justify-center space-x-4"> {/* 使用 flex 布局和 space-x-4 添加间距 */}
          <a href="#waitlist" className="bg-white text-blue-600 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-100 transition duration-300 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700">
            {t('cta.joinWaitlist')}
          </a>
          <a
              href='https://gnmhsvgysyaiigmsydpe.supabase.co/storage/v1/object/sign/static/web3insure_whitepaper_A_Decentralized_Transparent_and_Efficient_Insurance_Ecosystem.pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzdGF0aWMvd2ViM2luc3VyZV93aGl0ZXBhcGVyX0FfRGVjZW50cmFsaXplZF9UcmFuc3BhcmVudF9hbmRfRWZmaWNpZW50X0luc3VyYW5jZV9FY29zeXN0ZW0ucGRmIiwiaWF0IjoxNzMwMTE0MzI0LCJleHAiOjE3MzI3MDYzMjR9.x9euoLvhoAB0F7ljg29jW3IEPb99bsRQlXBQGVXKt3U&t=2024-10-28T11%3A18%3A45.141Z'
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-blue-600 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-100 transition duration-300 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
            >
              {t('cta.whitepaper')}
            </a>
            <a href="https://demo.web3insure.io/" className="bg-white text-blue-600 px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-100 transition duration-300 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
              target="_blank"
              rel="noopener noreferrer">
            {t('cta.demo')}
          </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;