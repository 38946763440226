import React from 'react';
import { useTranslation } from 'react-i18next';
import { Mail, Facebook, Linkedin, Twitter, Send, MessageCircle } from 'lucide-react';

const Contact: React.FC = () => {
  const { t } = useTranslation();

  const contactMethods = [
    { 
      icon: Mail, 
      label: 'Email', 
      value: 'zhangcheng@web3insure.com',
      href: 'mailto:zhangcheng@web3insure.com'
    },
    { 
      icon: Facebook, 
      label: 'Facebook', 
      value: 'facebook.com/web3insure',
      href: 'https://facebook.com/web3insure'
    },
    { 
      icon: Linkedin, 
      label: 'LinkedIn', 
      value: 'linkedin.com/company/web3insure',
      href: 'https://linkedin.com/company/web3insure'
    },
    { 
      icon: Twitter, 
      label: 'X (Twitter)', 
      value: 'x.com/web3insure',
      href: 'https://x.com/web3insure'
    },
    { 
      icon: Send, 
      label: 'Telegram', 
      value: 't.me/web3insure',
      href: 'https://t.me/web3insure'
    },
    { 
      icon: MessageCircle, 
      label: 'Discord', 
      value: 'discord.gg/web3insure',
      href: 'https://discord.gg/Kauyd5br'
    },
  ];

  return (
    <section id="contact" className="py-20 bg-gray-100 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center mb-12">
          <MessageCircle className="w-12 h-12 text-blue-600 dark:text-blue-400 mr-4" />
          <h2 className="text-3xl md:text-4xl font-bold text-center dark:text-white">{t('contact.title')}</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {contactMethods.map((method, index) => (
            <a
              key={index}
              href={method.href}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center bg-white p-6 rounded-lg shadow-md dark:bg-gray-700 hover:shadow-lg transition-shadow cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open(method.href, '_blank', 'noopener,noreferrer');
              }}
            >
              <method.icon className="w-8 h-8 text-blue-600 mr-4 dark:text-blue-400" />
              <div>
                <h3 className="font-semibold dark:text-white">{method.label}</h3>
                <p className="text-gray-600 dark:text-gray-300">{method.value}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Contact;
