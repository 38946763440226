import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import WaitlistForm from './components/WaitlistForm';
import Contact from './components/Contact';
import Footer from './components/Footer';
import LanguageSwitcher from './components/LanguageSwitcher';
import OperatingModel from './components/OperatingModel';
import DataMetrics from './components/DataMetrics';
import ValuePropositions from './components/ValuePropositions';
import IndustryInsights from './components/IndustryInsights';
import { ClerkProvider } from '@clerk/clerk-react';
import TeamSection from './components/TeamSection';

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    setIsRTL(dir === 'rtl');
    document.documentElement.dir = dir;
    document.documentElement.lang = i18n.language;

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(darkModeMediaQuery.matches);

    const handleColorSchemeChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
    };

    darkModeMediaQuery.addListener(handleColorSchemeChange);

    return () => {
      darkModeMediaQuery.removeListener(handleColorSchemeChange);
    };
  }, [i18n.language]);

  return (
    <ClerkProvider
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
      appearance={{
        elements: {
          formButtonPrimary: 'bg-blue-600 hover:bg-blue-700',
          footerActionLink: 'text-blue-600 hover:text-blue-700'
        }
      }}
    >
      <div className={`flex flex-col min-h-screen ${isRTL ? 'rtl' : 'ltr'} ${isDarkMode ? 'dark' : ''}`}>
        <Header />
        <main className="flex-grow">
          <Hero />
          <Features />
          <DataMetrics />
          <ValuePropositions />
          <IndustryInsights />
          <OperatingModel />
          <TeamSection />
          <WaitlistForm />
          <Contact />
        </main>
        <Footer />
        <LanguageSwitcher />
      </div>
    </ClerkProvider>
  );
};

export default App;